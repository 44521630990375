'use client'
import type { FC, ReactNode } from 'react'
import { usePathname } from 'next/navigation'
import styled from 'styled-components'
import { designGrid, mq } from '@/styles'

type Props = {
  children: ReactNode
}

export const DivPageWrapper:FC<Props> = ({ children }) => {
  const pathname = usePathname()
  return (
    <Div className={(pathname === '/' || pathname === '/es-ES') ? 'home' : ''}>
      {children}
    </Div>
  )
}

const Div = styled.div`
  ${designGrid({})}
  height: 100%;
  min-height: 100vh;

  ${mq.lessThan('tablet')} {
    grid-template-rows: auto 1fr auto;
  }

  &.home {
    background-color: var(--color-darkgreen);
  }
`
