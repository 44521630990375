'use client'
import type { FC, ReactNode } from 'react'
import StyledComponentsRegistry from '@/utils/styled-components-registry'
import { DivPageWrapper } from '@/components/DivPageWrapper'

type Props = {
  children: ReactNode
}

const ClientProviders:FC<Props> = ({ children }) => {
  return (
    <StyledComponentsRegistry>
      <DivPageWrapper>
        {children}
      </DivPageWrapper>
    </StyledComponentsRegistry>
  )
}

export default ClientProviders
